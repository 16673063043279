import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../themes";
import { mockDataInvoices, mockDonationData } from "../../data/mockData";
import Header from "../../components/Header";

const Donations = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  console.log(mockDonationData);
  console.log(mockDataInvoices);

  const columns = [
    {
      field: "donor_firstname",
      headerName: "First Name",
      flex: 1,
    },

    {
      field: "donor_lastname",
      headerName: "Last Name",
      flex: 1,
    },

    {
      field: "amount",
      headerName: "Donation Amount",
      flex: 1,
      enderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          ${params.row.cost}
        </Typography>
      ),
    },
    {
      field: "donor_phone",
      headerName: "Donor Phone Number",
      flex: 1,
    },

    {
      field: "paid_at",
      headerName: " Date Paid",
      flex: 1,
    },

    {
      field: "unique_identifier",
      headerName: "Donation ID",
      flex: 1,
    },

    {
      field: "recurring_period",
      headerName: "Recurring",
      flex: 1,
    },
    {
      field: "donor_city",
      headerName: "City",
      flex: 1,
    },
    {
      field: "donor_state",
      headerName: "State",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  ];
  return (
    <Box m="20px">
      <Header title="DONATIONS" subtitle="List of donations" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          getRowId={(row) => Math.floor(Math.random() * 100_000)}
          rows={mockDonationData}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Donations;
