import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../themes.js";
import { Link } from "react-router-dom";

import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";

import { useQuery } from "@tanstack/react-query";
import { get_data } from "../api/api.js";
import { mockDonationData } from "../data/mockData";

import LineChart from "./LineChart";

const RowTwo = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let newData;

  const dataQuery = useQuery({
    queryKey: ["dataQuery"],
    queryFn: get_data,
  });

  if (dataQuery.status === "success") {
    // newData = dataQuery.data.slice(0, 10);
    console.log(newData, "newData");
  }
  newData = mockDonationData;

  return (
    <>
      <Box
        gridColumn="span 8"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
      >
        <Box
          mt="25px"
          p="0 30px"
          display="flex "
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h5" fontWeight="600" color={colors.gray[100]}>
              Donation Generated
            </Typography>
            <Typography
              variant="h3"
              fontWeight="bold"
              color={colors.greenAccent[500]}
            >
              $913,314.53
            </Typography>
          </Box>
          <Box>
            <IconButton>
              <DownloadOutlinedIcon
                sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
              />
            </IconButton>
          </Box>
        </Box>
        <Link to="/Line">
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Link>
      </Box>
      <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
        overflow="auto"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`4px solid ${colors.primary[500]}`}
          colors={colors.gray[100]}
          p="15px"
        >
          <Typography color={colors.gray[100]} variant="h5" fontWeight="600">
            Recent donations
          </Typography>
        </Box>

        {!newData ? (
          <div>Loading...</div>
        ) : (
          newData.map((donation, i) => (
            <Box
              key={`${donation.id}`}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {donation.id}
                </Typography>
                <Typography color={colors.gray[100]}>
                  {`${donation.donor_firstname} ${donation.donor_lastname}`}
                </Typography>
              </Box>
              <Box color={colors.gray[100]}>
                {donation.created_at.split(" ")[0]}
              </Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                ${donation.amount}
              </Box>
            </Box>
          ))
        )}
      </Box>
    </>
  );
};

export default RowTwo;
