let headers = new Headers();

headers.append("Content-Type", "application/json");
headers.append("Accept", "application/json");
headers.append("Origin", "http://127.0.0.1:8000/");
headers.append("'Access-Control-Allow-Origin'", "*");

const url = "http://127.0.0.1:8000";
const payload = {
  mode: "cors",
  method: "GET",
  headers: headers,
};

export async function get_general_stats() {
  try {
    const response = await fetch(url + `/general_stats`, payload);
    console.log(response, "RESP");
    const response_data = await response.json();
    return response_data;
  } catch (error) {
    return error;
  }
}

export async function get_data() {
  try {
    const response = await fetch(url + `/get_data`, payload);
    console.log(response, "RESP");
    const response_data = await response.json();
    return response_data;
  } catch (error) {
    return error;
  }
}

export async function donationTotalByState() {
  try {
    const response = await fetch(url + `/donation_total_by_state`, payload);
    console.log(response, "RESP");
    const response_data = await response.json();
    return response_data;
  } catch (error) {
    return error;
  }
}
