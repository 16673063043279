import { Box, useTheme } from "@mui/material";
import Header from "../../components/Header";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tokens } from "../../themes";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="FAQ" subtitle="Frequently Asked Questions Page" />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What is the purpose of this project?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The purpose is to demo my full stack skills at making apps
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            What tools did you use?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The app is built using React.js and MaterialUI for the frontend, and
            FastAPI for the backend
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Who is the developer?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            My name is Em Harley, and I'm a developer with 6 years of experience
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography color={colors.greenAccent[500]} variant="h5">
            Where Can I find more about Em?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can find more about me at:
            <ul>
              <li>Personal website: https://emharley.com</li>
              <li>LinkedIn: https://www.linkedin.com/in/m-harley/</li>
              <li>Email: m.harley267@gmail.com</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FAQ;
