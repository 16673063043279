import { Box, useTheme } from "@mui/material";
import { tokens } from "../themes.js";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import StatBox from "./StatBox";

import { mockDonationAggs } from "../data/mockData";

import { useQuery } from "@tanstack/react-query";
import { get_general_stats } from "../api/api.js";

const RowOne = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // const generalStatsQuery = useQuery({
  //   queryKey: ["generalStats"],
  //   queryFn: get_general_stats,
  // });

  // console.log(generalStatsQuery.data, "DATA");
  // console.log(generalStatsQuery.status);

  let total_donation_count;
  let total_donors;
  let total_donation_amount;
  let average_donation;

  // if (generalStatsQuery.data) {
  //   ({
  //     total_donation_count,
  //     total_donors,
  //     total_donation_amount,
  //     average_donation,
  //   } = generalStatsQuery.data);
  // } else {
  total_donation_count = mockDonationAggs.mtotal_donation_count;
  total_donors = mockDonationAggs.mtotal_donors =
    mockDonationAggs.maverage_donation;
  total_donation_amount = mockDonationAggs.maverage_donation;
  average_donation = mockDonationAggs.maverage_donation;
  // }
  return (
    <>
      <Box
        gridColumn="span 3"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StatBox
          title={total_donation_count ? total_donation_count : "Loading..."}
          subtitle="Total Donation Count"
          progress="0.75"
          increase="+14%"
          icon={
            <EmailIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            />
          }
        />
      </Box>
      <Box
        gridColumn="span 3"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StatBox
          title={
            total_donation_amount
              ? total_donation_amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : "Loading..."
          }
          subtitle="Total Donation Amount"
          progress="0.50"
          increase="+21%"
          icon={
            <PointOfSaleIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            />
          }
        />
      </Box>
      <Box
        gridColumn="span 3"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StatBox
          title={total_donors ? total_donors : "Loading..."}
          subtitle="Total Donors"
          progress="0.30"
          increase="+5%"
          icon={
            <PersonAddIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            />
          }
        />
      </Box>
      <Box
        gridColumn="span 3"
        backgroundColor={colors.primary[400]}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <StatBox
          title={average_donation ? average_donation : "Loading..."}
          subtitle="Average Donation Amount"
          progress="0.80"
          increase="+43%"
          icon={
            <TrafficIcon
              sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            />
          }
        />
      </Box>
    </>
  );
};

export default RowOne;
