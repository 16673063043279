import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../themes";
import { mockBarData as mdata } from "../data/mockData";

import { useQuery } from "@tanstack/react-query";
import { donationTotalByState } from "../api/api.js";
import { mockDonationByState } from "../data/mockData";

const BarChart = ({ isDashboard = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let data;

  // const topDonationStates = (donationAggs) => {
  //   let top = [];
  //   let keys = Object.keys(donationAggs);
  //   keys.sort((a, b) => donationAggs[b] - donationAggs[a]);
  //   const mostState = keys.slice(0, 9);
  //   console.log(mostState, "Keys");

  //   mostState.forEach((key) => {
  //     top.push({
  //       country: key,
  //       donation_amount: donationAggs[key],
  //     });
  //     console.log(key, "states");
  //   });
  //   console.log(top, "toto");
  //   return { topStates: top, mostState };
  // };

  // const donationsByState = useQuery({
  //   queryKey: ["donationByState"],
  //   queryFn: donationTotalByState,
  // });

  // if (donationsByState.status === "success") {
  //   const { topStates, mostState } = topDonationStates(donationsByState.data);
  //   data = topStates;
  //   console.log(mdata);
  //   console.log(topStates, "TOP");
  //   console.log(mostState, "KEY");
  // } else {
  data = mockDonationByState;
  // return <div>Loading...</div>;
  // }
  return (
    <ResponsiveBar
      data={data}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.gray[100],
            },
          },
          legend: {
            text: {
              fill: colors.gray[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.gray[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.gray[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.gray[100],
          },
        },
      }}
      keys={["donation_amount"]}
      indexBy="country"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "accent" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: isDashboard ? undefined : "country", // changed
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: isDashboard ? undefined : "food", // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

export default BarChart;
