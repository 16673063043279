import { tokens } from "../themes";

export const mockDonationAggs = {
  mtotal_donation_count: 17713,
  mtotal_donors: 15289,
  mtotal_donation_count_amount: 913314.53,
  maverage_donation: 51.56,
};
export const mockDonationByState = [
  { country: "CA", donation_amount: 3490 },
  { country: "FL", donation_amount: 1484 },
  { country: "TX", donation_amount: 1242 },
  { country: "NY", donation_amount: 1191 },
  { country: "GA", donation_amount: 930 },
  { country: "VA", donation_amount: 775 },
  { country: "MA", donation_amount: 730 },
  { country: "MI", donation_amount: 617 },
  { country: "MD", donation_amount: 587 },
];
export const mockDataTeam = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    name: "Ned Stark",
    email: "ned.stark@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    name: "Brandon Grayjoy",
    email: "brandongrayjoy@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    name: "Wolf Stark",
    email: "wolkbarkbark@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];

export const mockDataContacts = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
    city: "New York",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    address: "1234 Main Street, New York, NY 10001",
    city: "New York",
    zipCode: "13151",
    registrarId: 123512,
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    address: "3333 Want Blvd, Estanza, NAY 42125",
    city: "New York",
    zipCode: "87281",
    registrarId: 4132513,
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    address: "1514 Main Street, New York, NY 22298",
    city: "New York",
    zipCode: "15551",
    registrarId: 123512,
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    address: "11122 Welping Ave, Tenting, CD 21321",
    city: "Tenting",
    zipCode: "14215",
    registrarId: 123512,
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    address: "1234 Canvile Street, Esvazark, NY 10001",
    city: "Esvazark",
    zipCode: "10001",
    registrarId: 123512,
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    address: "22215 Super Street, Everting, ZO 515234",
    city: "Evertin",
    zipCode: "51523",
    registrarId: 123512,
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    address: "4123 Ever Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 512315,
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    address: "51234 Avery Street, Cantory, ND 212412",
    city: "Colunza",
    zipCode: "111234",
    registrarId: 928397,
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    age: 42,
    phone: "(222)444-5555",
    address: "4123 Easer Blvd, Wentington, AD 142213",
    city: "Esteras",
    zipCode: "44215",
    registrarId: 533215,
  },
  {
    id: 11,
    name: "Steve Goodman",
    email: "stevegoodmane@gmail.com",
    age: 11,
    phone: "(444)555-6239",
    address: "51234 Fiveton Street, CunFory, ND 212412",
    city: "Colunza",
    zipCode: "1234",
    registrarId: 92197,
  },
];

export const mockDataInvoices = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    cost: "21.24",
    phone: "(665)121-5454",
    date: "03/12/2022",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    cost: "1.24",
    phone: "(421)314-2288",
    date: "06/15/2021",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    cost: "11.24",
    phone: "(422)982-6739",
    date: "05/02/2022",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    cost: "80.55",
    phone: "(921)425-6742",
    date: "03/21/2022",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    cost: "1.24",
    phone: "(421)445-1189",
    date: "01/12/2021",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    cost: "63.12",
    phone: "(232)545-6483",
    date: "11/02/2022",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    cost: "52.42",
    phone: "(543)124-0123",
    date: "02/11/2022",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    cost: "21.24",
    phone: "(222)444-5555",
    date: "05/02/2021",
  },
];

export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockBarData = [
  {
    country: "AD",
    "hot dog": 137,
    // "hot dogColor": "hsl(229, 70%, 50%)",
    // burger: 96,
    // burgerColor: "hsl(296, 70%, 50%)",
    // kebab: 72,
    // kebabColor: "hsl(97, 70%, 50%)",
    // donut: 140,
    // donutColor: "hsl(340, 70%, 50%)",
  },
  // {
  //   country: "AE",
  //   "hot dog": 55,
  //   "hot dogColor": "hsl(307, 70%, 50%)",
  //   burger: 28,
  //   burgerColor: "hsl(111, 70%, 50%)",
  //   kebab: 58,
  //   kebabColor: "hsl(273, 70%, 50%)",
  //   donut: 29,
  //   donutColor: "hsl(275, 70%, 50%)",
  // },
  // {
  //   country: "AF",
  //   "hot dog": 109,
  //   "hot dogColor": "hsl(72, 70%, 50%)",
  //   burger: 23,
  //   burgerColor: "hsl(96, 70%, 50%)",
  //   kebab: 34,
  //   kebabColor: "hsl(106, 70%, 50%)",
  //   donut: 152,
  //   donutColor: "hsl(256, 70%, 50%)",
  // },
  // {
  //   country: "AG",
  //   "hot dog": 133,
  //   "hot dogColor": "hsl(257, 70%, 50%)",
  //   burger: 52,
  //   burgerColor: "hsl(326, 70%, 50%)",
  //   kebab: 43,
  //   kebabColor: "hsl(110, 70%, 50%)",
  //   donut: 83,
  //   donutColor: "hsl(9, 70%, 50%)",
  // },
  // {
  //   country: "AI",
  //   "hot dog": 81,
  //   "hot dogColor": "hsl(190, 70%, 50%)",
  //   burger: 80,
  //   burgerColor: "hsl(325, 70%, 50%)",
  //   kebab: 112,
  //   kebabColor: "hsl(54, 70%, 50%)",
  //   donut: 35,
  //   donutColor: "hsl(285, 70%, 50%)",
  // },
  // {
  //   country: "AL",
  //   "hot dog": 66,
  //   "hot dogColor": "hsl(208, 70%, 50%)",
  //   burger: 111,
  //   burgerColor: "hsl(334, 70%, 50%)",
  //   kebab: 167,
  //   kebabColor: "hsl(182, 70%, 50%)",
  //   donut: 18,
  //   donutColor: "hsl(76, 70%, 50%)",
  // },
  // {
  //   country: "AM",
  //   "hot dog": 80,
  //   "hot dogColor": "hsl(87, 70%, 50%)",
  //   burger: 47,
  //   burgerColor: "hsl(141, 70%, 50%)",
  //   kebab: 158,
  //   kebabColor: "hsl(224, 70%, 50%)",
  //   donut: 49,
  //   donutColor: "hsl(274, 70%, 50%)",
  // },
];

export const mockPieData = [
  {
    id: "GA",
    label: "GA",
    value: 239,
    color: "hsl(104, 70%, 50%)",
  },
  {
    id: "NY",
    label: "NY",
    value: 170,
    color: "hsl(162, 70%, 50%)",
  },
  {
    id: "TX",
    label: "TX",
    value: 322,
    color: "hsl(291, 70%, 50%)",
  },
  {
    id: "FL",
    label: "FL",
    value: 503,
    color: "hsl(229, 70%, 50%)",
  },
  {
    id: "CA",
    label: "CA",
    value: 584,
    color: "hsl(344, 70%, 50%)",
  },
];

export const mockLineData = [
  {
    id: "texas",
    color: tokens("dark").greenAccent[500],
    data: [
      {
        x: "january",
        y: 101,
      },
      {
        x: "february",
        y: 75,
      },
      {
        x: "march",
        y: 36,
      },
      {
        x: "april",
        y: 216,
      },
      {
        x: "may",
        y: 35,
      },
      {
        x: "june",
        y: 236,
      },
      {
        x: "july",
        y: 88,
      },
      {
        x: "august",
        y: 232,
      },
      {
        x: "september",
        y: 281,
      },
      {
        x: "october",
        y: 1,
      },
      {
        x: "november",
        y: 35,
      },
      {
        x: "december",
        y: 14,
      },
    ],
  },
  {
    id: "florida",
    color: tokens("dark").blueAccent[300],
    data: [
      {
        x: "january",
        y: 212,
      },
      {
        x: "february",
        y: 190,
      },
      {
        x: "march",
        y: 270,
      },
      {
        x: "april",
        y: 9,
      },
      {
        x: "may",
        y: 75,
      },
      {
        x: "june",
        y: 175,
      },
      {
        x: "july",
        y: 33,
      },
      {
        x: "august",
        y: 189,
      },
      {
        x: "september",
        y: 97,
      },
      {
        x: "october",
        y: 87,
      },
      {
        x: "november",
        y: 299,
      },
      {
        x: "december",
        y: 251,
      },
    ],
  },
  {
    id: "california",
    color: tokens("dark").redAccent[200],
    data: [
      {
        x: "january",
        y: 191,
      },
      {
        x: "february",
        y: 136,
      },
      {
        x: "march",
        y: 91,
      },
      {
        x: "april",
        y: 190,
      },
      {
        x: "may",
        y: 211,
      },
      {
        x: "june",
        y: 152,
      },
      {
        x: "july",
        y: 189,
      },
      {
        x: "august",
        y: 152,
      },
      {
        x: "september",
        y: 8,
      },
      {
        x: "october",
        y: 197,
      },
      {
        x: "november",
        y: 107,
      },
      {
        x: "december",
        y: 170,
      },
    ],
  },
];

export const mockGeographyData = [
  {
    id: "AFG",
    value: 520600,
  },
  {
    id: "AGO",
    value: 949905,
  },
  {
    id: "ALB",
    value: 329910,
  },
  {
    id: "ARE",
    value: 675484,
  },
  {
    id: "ARG",
    value: 432239,
  },
  {
    id: "ARM",
    value: 288305,
  },
  {
    id: "ATA",
    value: 415648,
  },
  {
    id: "ATF",
    value: 665159,
  },
  {
    id: "AUT",
    value: 798526,
  },
  {
    id: "AZE",
    value: 481678,
  },
  {
    id: "BDI",
    value: 496457,
  },
  {
    id: "BEL",
    value: 252276,
  },
  {
    id: "BEN",
    value: 440315,
  },
  {
    id: "BFA",
    value: 343752,
  },
  {
    id: "BGD",
    value: 920203,
  },
  {
    id: "BGR",
    value: 261196,
  },
  {
    id: "BHS",
    value: 421551,
  },
  {
    id: "BIH",
    value: 974745,
  },
  {
    id: "BLR",
    value: 349288,
  },
  {
    id: "BLZ",
    value: 305983,
  },
  {
    id: "BOL",
    value: 430840,
  },
  {
    id: "BRN",
    value: 345666,
  },
  {
    id: "BTN",
    value: 649678,
  },
  {
    id: "BWA",
    value: 319392,
  },
  {
    id: "CAF",
    value: 722549,
  },
  {
    id: "CAN",
    value: 332843,
  },
  {
    id: "CHE",
    value: 122159,
  },
  {
    id: "CHL",
    value: 811736,
  },
  {
    id: "CHN",
    value: 593604,
  },
  {
    id: "CIV",
    value: 143219,
  },
  {
    id: "CMR",
    value: 630627,
  },
  {
    id: "COG",
    value: 498556,
  },
  {
    id: "COL",
    value: 660527,
  },
  {
    id: "CRI",
    value: 60262,
  },
  {
    id: "CUB",
    value: 177870,
  },
  {
    id: "-99",
    value: 463208,
  },
  {
    id: "CYP",
    value: 945909,
  },
  {
    id: "CZE",
    value: 500109,
  },
  {
    id: "DEU",
    value: 63345,
  },
  {
    id: "DJI",
    value: 634523,
  },
  {
    id: "DNK",
    value: 731068,
  },
  {
    id: "DOM",
    value: 262538,
  },
  {
    id: "DZA",
    value: 760695,
  },
  {
    id: "ECU",
    value: 301263,
  },
  {
    id: "EGY",
    value: 148475,
  },
  {
    id: "ERI",
    value: 939504,
  },
  {
    id: "ESP",
    value: 706050,
  },
  {
    id: "EST",
    value: 977015,
  },
  {
    id: "ETH",
    value: 461734,
  },
  {
    id: "FIN",
    value: 22800,
  },
  {
    id: "FJI",
    value: 18985,
  },
  {
    id: "FLK",
    value: 64986,
  },
  {
    id: "FRA",
    value: 447457,
  },
  {
    id: "GAB",
    value: 669675,
  },
  {
    id: "GBR",
    value: 757120,
  },
  {
    id: "GEO",
    value: 158702,
  },
  {
    id: "GHA",
    value: 893180,
  },
  {
    id: "GIN",
    value: 877288,
  },
  {
    id: "GMB",
    value: 724530,
  },
  {
    id: "GNB",
    value: 387753,
  },
  {
    id: "GNQ",
    value: 706118,
  },
  {
    id: "GRC",
    value: 377796,
  },
  {
    id: "GTM",
    value: 66890,
  },
  {
    id: "GUY",
    value: 719300,
  },
  {
    id: "HND",
    value: 739590,
  },
  {
    id: "HRV",
    value: 929467,
  },
  {
    id: "HTI",
    value: 538961,
  },
  {
    id: "HUN",
    value: 146095,
  },
  {
    id: "IDN",
    value: 490681,
  },
  {
    id: "IND",
    value: 549818,
  },
  {
    id: "IRL",
    value: 630163,
  },
  {
    id: "IRN",
    value: 596921,
  },
  {
    id: "IRQ",
    value: 767023,
  },
  {
    id: "ISL",
    value: 478682,
  },
  {
    id: "ISR",
    value: 963688,
  },
  {
    id: "ITA",
    value: 393089,
  },
  {
    id: "JAM",
    value: 83173,
  },
  {
    id: "JOR",
    value: 52005,
  },
  {
    id: "JPN",
    value: 199174,
  },
  {
    id: "KAZ",
    value: 181424,
  },
  {
    id: "KEN",
    value: 60946,
  },
  {
    id: "KGZ",
    value: 432478,
  },
  {
    id: "KHM",
    value: 254461,
  },
  {
    id: "OSA",
    value: 942447,
  },
  {
    id: "KWT",
    value: 414413,
  },
  {
    id: "LAO",
    value: 448339,
  },
  {
    id: "LBN",
    value: 620090,
  },
  {
    id: "LBR",
    value: 435950,
  },
  {
    id: "LBY",
    value: 75091,
  },
  {
    id: "LKA",
    value: 595124,
  },
  {
    id: "LSO",
    value: 483524,
  },
  {
    id: "LTU",
    value: 867357,
  },
  {
    id: "LUX",
    value: 689172,
  },
  {
    id: "LVA",
    value: 742980,
  },
  {
    id: "MAR",
    value: 236538,
  },
  {
    id: "MDA",
    value: 926836,
  },
  {
    id: "MDG",
    value: 840840,
  },
  {
    id: "MEX",
    value: 353910,
  },
  {
    id: "MKD",
    value: 505842,
  },
  {
    id: "MLI",
    value: 286082,
  },
  {
    id: "MMR",
    value: 915544,
  },
  {
    id: "MNE",
    value: 609500,
  },
  {
    id: "MNG",
    value: 410428,
  },
  {
    id: "MOZ",
    value: 32868,
  },
  {
    id: "MRT",
    value: 375671,
  },
  {
    id: "MWI",
    value: 591935,
  },
  {
    id: "MYS",
    value: 991644,
  },
  {
    id: "NAM",
    value: 701897,
  },
  {
    id: "NCL",
    value: 144098,
  },
  {
    id: "NER",
    value: 312944,
  },
  {
    id: "NGA",
    value: 862877,
  },
  {
    id: "NIC",
    value: 90831,
  },
  {
    id: "NLD",
    value: 281879,
  },
  {
    id: "NOR",
    value: 224537,
  },
  {
    id: "NPL",
    value: 322331,
  },
  {
    id: "NZL",
    value: 86615,
  },
  {
    id: "OMN",
    value: 707881,
  },
  {
    id: "PAK",
    value: 158577,
  },
  {
    id: "PAN",
    value: 738579,
  },
  {
    id: "PER",
    value: 248751,
  },
  {
    id: "PHL",
    value: 557292,
  },
  {
    id: "PNG",
    value: 516874,
  },
  {
    id: "POL",
    value: 682137,
  },
  {
    id: "PRI",
    value: 957399,
  },
  {
    id: "PRT",
    value: 846430,
  },
  {
    id: "PRY",
    value: 720555,
  },
  {
    id: "QAT",
    value: 478726,
  },
  {
    id: "ROU",
    value: 259318,
  },
  {
    id: "RUS",
    value: 268735,
  },
  {
    id: "RWA",
    value: 136781,
  },
  {
    id: "ESH",
    value: 151957,
  },
  {
    id: "SAU",
    value: 111821,
  },
  {
    id: "SDN",
    value: 927112,
  },
  {
    id: "SDS",
    value: 966473,
  },
  {
    id: "SEN",
    value: 158085,
  },
  {
    id: "SLB",
    value: 178389,
  },
  {
    id: "SLE",
    value: 528433,
  },
  {
    id: "SLV",
    value: 353467,
  },
  {
    id: "ABV",
    value: 251,
  },
  {
    id: "SOM",
    value: 445243,
  },
  {
    id: "SRB",
    value: 202402,
  },
  {
    id: "SUR",
    value: 972121,
  },
  {
    id: "SVK",
    value: 319923,
  },
  {
    id: "SVN",
    value: 728766,
  },
  {
    id: "SWZ",
    value: 379669,
  },
  {
    id: "SYR",
    value: 16221,
  },
  {
    id: "TCD",
    value: 101273,
  },
  {
    id: "TGO",
    value: 498411,
  },
  {
    id: "THA",
    value: 506906,
  },
  {
    id: "TJK",
    value: 613093,
  },
  {
    id: "TKM",
    value: 327016,
  },
  {
    id: "TLS",
    value: 607972,
  },
  {
    id: "TTO",
    value: 936365,
  },
  {
    id: "TUN",
    value: 898416,
  },
  {
    id: "TUR",
    value: 237783,
  },
  {
    id: "TWN",
    value: 878213,
  },
  {
    id: "TZA",
    value: 442174,
  },
  {
    id: "UGA",
    value: 720710,
  },
  {
    id: "UKR",
    value: 74172,
  },
  {
    id: "URY",
    value: 753177,
  },
  {
    id: "USA",
    value: 658725,
  },
  {
    id: "UZB",
    value: 550313,
  },
  {
    id: "VEN",
    value: 707492,
  },
  {
    id: "VNM",
    value: 538907,
  },
  {
    id: "VUT",
    value: 650646,
  },
  {
    id: "PSE",
    value: 476078,
  },
  {
    id: "YEM",
    value: 957751,
  },
  {
    id: "ZAF",
    value: 836949,
  },
  {
    id: "ZMB",
    value: 714503,
  },
  {
    id: "ZWE",
    value: 405217,
  },
  {
    id: "KOR",
    value: 171135,
  },
];

export const mockDonationData = [
  {
    id: 13763843,
    donor_firstname: "Cynthia",
    donor_lastname: "Jeremy",
    donor_addr1: null,
    donor_city: "TUCSON",
    donor_state: "AZ",
    donor_zip: "85741",
    donor_is_eligible_for_express_lane: 1,
    donor_email: null,
    donor_phone: "+18881125520",
    created_at: "2021-05-20 03:24:47.000000 +00:00",
    order_number: "SB222237918",
    contribution_form: "Cheetah",
    refcodes: '{"refcode": "ref-Crane"}',
    refcode: "ref-Crane",
    recurring_period: "monthly",
    recurring_duration: "infinite",
    is_paypal: 0,
    is_mobile: 1,
    is_express: 1,
    with_express_lane: 0,
    express_signup: 0,
    unique_identifier: "qxHMqZAvjF",
    status: "approved",
    text_message_option: "unknown",
    custom_fields: "[]",
    sequence: 1,
    entity_id: 1,
    committee_name: "Eric for Dogcatcher",
    amount: 10,
    paid_at: "2021-06-19 08:21:19.000000 +00:00",
    lineitem_id: "500314606",
    form_name: "Cheetah",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_managing_entity_committee_name: "Eric for Dogcatcher",
  },
  {
    amount: 25.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bug",
    created_at: "2021-06-19 17:55:26.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Munster",
    donor_email: null,
    donor_firstname: "Julylos",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Victor",
    donor_phone: "+18881125524",
    donor_state: "IN",
    donor_zip: "46321",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bug",
    id: 13763676,
    is_express: 1,
    is_mobile: 1,
    is_paypal: 0,
    lineitem_id: "521594716",
    order_number: "SB222237922",
    paid_at: "2021-06-19 17:55:31.000000 +00:00",
    recurring_duration: "infinite",
    recurring_period: "monthly",
    refcode: "ref-Duck",
    refcodes: '{"refcode": "ref-Duck"}',
    sequence: 0,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "HixsAZFKjN",
    with_express_lane: 0,
  },
  {
    amount: 10.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bug",
    created_at: "2021-06-19 18:46:56.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Middle Village",
    donor_email: null,
    donor_firstname: "Brett",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Matthew",
    donor_phone: "+18881125525",
    donor_state: "NY",
    donor_zip: "11379",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bug",
    id: 13763665,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 0,
    lineitem_id: "723375484",
    order_number: "SB222237923",
    paid_at: "2021-06-19 18:47:01.000000 +00:00",
    recurring_duration: "infinite",
    recurring_period: "monthly",
    refcode: "ref-Clownfish",
    refcodes: '{"refcode": "ref-Clownfish"}',
    sequence: 0,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "gLqzbEvMyi",
    with_express_lane: 0,
  },
  {
    amount: 18.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bird",
    created_at: "2021-02-01 15:45:22.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Wethersfield",
    donor_email: null,
    donor_firstname: "Leah",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Brett",
    donor_phone: "+18881111114",
    donor_state: "CT",
    donor_zip: "06109",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bird",
    id: 13773089,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 0,
    lineitem_id: "623586096",
    order_number: "SB222222225",
    paid_at: "2021-06-01 08:22:45.000000 +00:00",
    recurring_duration: "infinite",
    recurring_period: "monthly",
    refcode: "ref-Dinosaur",
    refcodes: '{"refcode": "ref-Dinosaur"}',
    sequence: 4,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "rjjxmviluh",
    with_express_lane: 0,
  },
  {
    amount: 100.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Chimpanzee",
    created_at: "2021-07-31 11:37:58.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Plano",
    donor_email: null,
    donor_firstname: "Jason",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Curtis",
    donor_phone: "+18881111182",
    donor_state: "TX",
    donor_zip: "75075",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Chimpanzee",
    id: 13742165,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 0,
    lineitem_id: "562165935",
    order_number: "SB222222293",
    paid_at: "2021-07-31 11:38:13.000000 +00:00",
    recurring_duration: "1",
    recurring_period: "once",
    refcode: "ref-Dragon",
    refcodes: '{"refcode": "ref-Dragon"}',
    sequence: 0,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "rnMrfSmuls",
    with_express_lane: 0,
  },
  {
    amount: 10.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Catshark",
    created_at: "2021-09-26 00:41:17.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Rio Linda",
    donor_email: null,
    donor_firstname: "Dennis",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Derrick",
    donor_phone: "+18881111261",
    donor_state: "CA",
    donor_zip: "95673",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Catshark",
    id: 14550421,
    is_express: 1,
    is_mobile: 1,
    is_paypal: 0,
    lineitem_id: "607507419",
    order_number: "SB222222373",
    paid_at: "2021-09-26 00:41:23.000000 +00:00",
    recurring_duration: "1",
    recurring_period: "once",
    refcode: "ref-Dragon",
    refcodes: '{"refcode": "ref-Dragon"}',
    sequence: 0,
    status: "approved",
    text_message_option: "opt_out",
    unique_identifier: "AXdKNYqgCJ",
    with_express_lane: 0,
  },
  {
    amount: 250.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bird",
    created_at: "2021-08-30 21:30:14.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Palmdale",
    donor_email: null,
    donor_firstname: "Alex",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Adrian",
    donor_phone: "+18881111313",
    donor_state: "CA",
    donor_zip: "93551",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bird",
    id: 13805010,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 0,
    lineitem_id: "275481259",
    order_number: "SB222222426",
    paid_at: "2021-08-30 21:30:26.000000 +00:00",
    recurring_duration: "1",
    recurring_period: "once",
    refcode: "ref-Dinosaur",
    refcodes: '{"refcode": "ref-Dinosaur"}',
    sequence: 0,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "fTGWViIYMQ",
    with_express_lane: 1,
  },
  {
    amount: 5.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Camel",
    created_at: "2021-09-21 20:34:57.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Austin",
    donor_email: null,
    donor_firstname: "Paul",
    donor_is_eligible_for_express_lane: 0,
    donor_lastname: "Todd",
    donor_phone: "+18881111578",
    donor_state: "TX",
    donor_zip: "78750",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Camel",
    id: 14356825,
    is_express: 0,
    is_mobile: 1,
    is_paypal: 1,
    lineitem_id: "701619321",
    order_number: "SB222222692",
    paid_at: "2021-09-21 20:35:19.000000 +00:00",
    recurring_duration: "1",
    recurring_period: "once",
    refcode: "ref-Cougar",
    refcodes: '{"refcode": "ref-Cougar"}',
    sequence: 0,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "eLYQcYSoDg",
    with_express_lane: 0,
  },
  {
    amount: 50.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bug",
    created_at: "2021-06-08 20:34:32.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Louisville",
    donor_email: null,
    donor_firstname: "Rachel",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Thomas",
    donor_phone: "+18881112587",
    donor_state: "KY",
    donor_zip: "40208",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bug",
    id: 13770257,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 0,
    lineitem_id: "369314161",
    order_number: "SB222223721",
    paid_at: "2021-06-08 20:34:40.000000 +00:00",
    recurring_duration: "1",
    recurring_period: "once",
    refcode: "ref-Cow",
    refcodes: '{"refcode": "ref-Cow"}',
    sequence: 0,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "bClsogMdej",
    with_express_lane: 0,
  },
  {
    amount: 50.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "julydinal",
    created_at: "2021-09-17 22:31:05.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Hartford",
    donor_email: null,
    donor_firstname: "Angela",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Robert",
    donor_phone: "+18881112950",
    donor_state: "KY",
    donor_zip: "42347",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "julydinal",
    id: 14314993,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 0,
    lineitem_id: "731635613",
    order_number: "SB222224092",
    paid_at: "2021-09-17 22:31:17.000000 +00:00",
    recurring_duration: "1",
    recurring_period: "once",
    refcode: "ref-Deer",
    refcodes: '{"refcode": "ref-Deer"}',
    sequence: 0,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "QMsCFiHBPV",
    with_express_lane: 1,
  },
  {
    amount: 10.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bug",
    created_at: "2021-09-01 22:36:02.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Park Ridge",
    donor_email: null,
    donor_firstname: "Dennis",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Krystal",
    donor_phone: "+18881113525",
    donor_state: "IL",
    donor_zip: "60068",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bug",
    id: 13932975,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 0,
    lineitem_id: "169878006",
    order_number: "SB222224680",
    paid_at: "2021-09-01 22:36:10.000000 +00:00",
    recurring_duration: "1",
    recurring_period: "once",
    refcode: "ref-Eel",
    refcodes: '{"refcode": "ref-Eel"}',
    sequence: 0,
    status: "approved",
    text_message_option: "opt_out",
    unique_identifier: "LgyvhsiyWD",
    with_express_lane: 0,
  },
  {
    amount: 25.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bug",
    created_at: "2021-06-19 20:30:52.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Longwood",
    donor_email: null,
    donor_firstname: "Elizabeth",
    donor_is_eligible_for_express_lane: 0,
    donor_lastname: "Amy",
    donor_phone: "+18881125527",
    donor_state: "FL",
    donor_zip: "32779",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bug",
    id: 13763647,
    is_express: 0,
    is_mobile: 0,
    is_paypal: 1,
    lineitem_id: "707032369",
    order_number: "SB222237925",
    paid_at: "2021-06-19 20:30:56.000000 +00:00",
    recurring_duration: "infinite",
    recurring_period: "monthly",
    refcode: "ref-Deer",
    refcodes: '{"refcode": "ref-Deer"}',
    sequence: 0,
    status: "approved",
    text_message_option: "opt_out",
    unique_identifier: "kNNirGjTiP",
    with_express_lane: 0,
  },
  {
    amount: 10.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bug",
    created_at: "2021-06-19 20:32:15.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Indian Wells",
    donor_email: null,
    donor_firstname: "William",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Amy",
    donor_phone: "+18881125528",
    donor_state: "CA",
    donor_zip: "92210",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bug",
    id: 13763631,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 0,
    lineitem_id: "322344844",
    order_number: "SB222237926",
    paid_at: "2021-06-19 20:32:20.000000 +00:00",
    recurring_duration: "infinite",
    recurring_period: "monthly",
    refcode: "ref-Clownfish",
    refcodes: '{"refcode": "ref-Clownfish"}',
    sequence: 0,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "LttSRudfFG",
    with_express_lane: 0,
  },
  {
    amount: 12.5,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Caterpillar",
    created_at: "2020-11-20 13:54:04.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Henrico",
    donor_email: null,
    donor_firstname: "Christine",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "David",
    donor_phone: "+18881125532",
    donor_state: "VA",
    donor_zip: "23231",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Caterpillar",
    id: 13763530,
    is_express: 1,
    is_mobile: 1,
    is_paypal: 0,
    lineitem_id: "944255841",
    order_number: "SB222237931",
    paid_at: "2021-06-20 08:16:51.000000 +00:00",
    recurring_duration: "infinite",
    recurring_period: "monthly",
    refcode: "ref-Crow",
    refcodes: '{"refcode": "ref-Crow"}',
    sequence: 7,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "ELdQvmSZgC",
    with_express_lane: 0,
  },
  {
    amount: 5.0,
    committee_name: "Eric for Dogcatcher",
    contribution_form: "Bug",
    created_at: "2021-03-01 16:48:44.000000 +00:00",
    custom_fields: "[]",
    donor_addr1: null,
    donor_city: "Chicago",
    donor_email: null,
    donor_firstname: "Nathan",
    donor_is_eligible_for_express_lane: 1,
    donor_lastname: "Tyler",
    donor_phone: "+18881111116",
    donor_state: "IL",
    donor_zip: "60649",
    entity_id: 1,
    express_signup: 0,
    form_managing_entity_committee_name: "Eric for Dogcatcher",
    form_managing_entity_name: "Eric for Dogcatcher",
    form_name: "Bug",
    id: 13773090,
    is_express: 1,
    is_mobile: 0,
    is_paypal: 1,
    lineitem_id: "548239782",
    order_number: "SB222222227",
    paid_at: "2021-06-01 08:23:31.000000 +00:00",
    recurring_duration: "infinite",
    recurring_period: "monthly",
    refcode: "ref-Cod",
    refcodes: '{"refcode": "ref-Cod"}',
    sequence: 3,
    status: "approved",
    text_message_option: "unknown",
    unique_identifier: "rdHzwkDNIC",
    with_express_lane: 0,
  },
];
