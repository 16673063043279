import { ColorModeContext, useMode } from "./themes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import { Routes, Route } from "react-router-dom";

import Sidebar from "./scenes/global/Sidebar";
import Donations from "./scenes/donations";

import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import ProfileForm from "./scenes/profileForm";
import FAQ from "./scenes/faq";

// import Invoices from "./scenes/Invoices";
import Bar from "./scenes/bar";
import Dashboard from "./scenes/dashboard";

import Line from "./scenes/line";
import Pie from "./scenes/pie";
import Geography from "./scenes/geography";
import Calendar from "./scenes/calendar/calendar";

function App() {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Sidebar></Sidebar>
          <main className="content">
            <Topbar />

            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/team" element={<Team />} />

              <Route path="/donations" element={<Donations />} />
              {/* <Route path="iInvoices" element={<Invoices />} /> */}
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/profileform" element={<ProfileForm />} />

              <Route path="/bar" element={<Bar />} />
              <Route path="/line" element={<Line />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/calendar" element={<Calendar />} />
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
