import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../themes.js";
import { useNavigate, Link } from "react-router-dom";

import GeographyChart from "./GeographyChart";
import BarChart from "./BarChart";
import ProgressCircle from "./ProgressCircle";

const RowThree = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const navigateToLink = (link) => {
    console.log(link);
    navigate(link);
  };
  return (
    <>
      <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
        p="30px"
      >
        <Typography variant="h5" fontWeight="600">
          Campaign
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="25px"
        >
          <ProgressCircle size="125" />
          <Typography
            variant="h5"
            color={colors.greenAccent[500]}
            sx={{ mt: "15px" }}
          >
            $448,352 Recurring Donation
          </Typography>
          <Typography>Includes extra misc expenditures and costs</Typography>
        </Box>
      </Box>
      <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
      >
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ padding: "30px 30px 0 30px" }}
        >
          Sales Quantity
        </Typography>
        <Link to="/bar">
          <Box height="250px" mt="-20px">
            <BarChart isDashboard={true} />
          </Box>
        </Link>
      </Box>

      <Box
        gridColumn="span 4"
        gridRow="span 2"
        backgroundColor={colors.primary[400]}
        padding="30px"
      >
        <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "15px" }}>
          Geography Based Traffic
        </Typography>

        <Link to="/geography">
          <Box height="200px" onClick={navigateToLink("/geography")}>
            <GeographyChart isDashboard={true} />
          </Box>
        </Link>
      </Box>
    </>
  );
};

export default RowThree;
